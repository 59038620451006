import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="inner-banner py-5">
        <section className="w3l-breadcrumb text-left py-sm-5 ">
          <div className="container">
            <div className="w3breadcrumb-gids">
              <div className="w3breadcrumb-left text-left">
                <h2 className="inner-w3-title mt-lg-5 mt-4">
                  Business Trading Solutions{" "}
                </h2>
              </div>
              <div className="w3breadcrumb-right">
                <ul className="breadcrumbs-custom-path">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li className="active">
                    <span className="fas fa-arrow-right mx-2" /> Business
                    Trading Solutions{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="w3l-ab-page py-5" id="about1">
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="row align-items-center">
            <div className="col-lg-6 pe-lg-5">
              <h6 className="title-subw3hny mb-2">
                Business Trading Solutions
              </h6>
              <h3 className="title-w3l mb-2">Fashion</h3>
              <p className="mt-3">
                In the ever-evolving fashion industry, we are at the forefront
                of addressing new logistics challenges as we expand into new
                markets and cities. Our commitment to providing expedient
                reactions, excellent quality, and convenient deliveries align
                with the demands of multiple sales channels and online
                retailing. At {companyname}, we continuously strive to set
                new logistics standards by combining our decades of experience,
                industry knowledge, and quality standards with innovative and
                efficient logistics solutions that benefit both our clients and
                ourselves.
              </p>
            </div>
            <div className="col-lg-5 offset-lg-1 text-end mt-lg-0 mt-5 position-relative">
              <img
                src="assets/images/Fashion.jpg"
                alt=""
                className="img-fluid radius-image"
              />
              <div className="imginfo__box">
                <p>Fashion</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="w3l-ab-page py-5" id="about1">
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="row align-items-center">
            <div className="col-lg-5 offset-lg-1 text-end mt-lg-0 mt-5 position-relative">
              <img
                src="assets/images/Consumer & Retail.jpg"
                alt=""
                className="img-fluid radius-image"
              />
              <div className="imginfo__box">
                <p>Consumer & Retail</p>
              </div>
            </div>{" "}
            <div className="col-lg-6 pe-lg-5">
              <h6 className="title-subw3hny mb-2">
                Business Trading Solutions
              </h6>
              <h3 className="title-w3l mb-2">Consumer & Retail</h3>
              <p className="mt-3">
                We understand the complexities of the supply chains for the
                largest FMCG manufacturers and retailers globally. Our processes
                enhance speed to market, reduce product damage, and strengthen
                relationships with key business partners. {companyname}
                assists clients in managing increasing complexity, shorter
                product life cycles, and dynamic supply chains while effectively
                controlling costs and enabling them to meet regular demand,
                access new sales channels, launch new products, and source
                globally.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="w3l-ab-page py-5" id="about1">
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="row align-items-center">
            <div className="col-lg-6 pe-lg-5">
              <h6 className="title-subw3hny mb-2">
                Business Trading Solutions
              </h6>
              <h3 className="title-w3l mb-2">Automotive</h3>
              <p className="mt-3">
                The automotive industry, a significant contributor to the global
                economy, faces multifaceted challenges. {companyname}
                offers solutions designed to reduce costs and enhance
                efficiency. Our services aim to streamline processes, improve
                delivery accuracy, and simplify intricate supply chains. We
                empower clients to build closer customer relationships and
                respond swiftly to changing economic landscapes while minimizing
                transit damages.
              </p>
            </div>
            <div className="col-lg-5 offset-lg-1 text-end mt-lg-0 mt-5 position-relative">
              <img
                src="assets/images/Automotive.jpg"
                alt=""
                className="img-fluid radius-image"
              />
              <div className="imginfo__box">
                <p>Automotive</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="w3l-ab-page py-5" id="about1">
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="row align-items-center">
            <div className="col-lg-5 offset-lg-1 text-end mt-lg-0 mt-5 position-relative">
              <img
                src="assets/images/Technology.jpg"
                alt=""
                className="img-fluid radius-image"
              />
              <div className="imginfo__box">
                <p>Technology</p>
              </div>
            </div>{" "}
            <div className="col-lg-6 pe-lg-5">
              <h6 className="title-subw3hny mb-2">
                Business Trading Solutions
              </h6>
              <h3 className="title-w3l mb-2">Technology</h3>
              <p className="mt-3">
                In the technology sector, characterized by constant innovation
                and fierce global competition, we provide cutting-edge logistics
                solutions tailored to the industry's demands. Our services
                encompass operations like high-speed product assembly,
                packaging, last-minute re-flashing, software updates, kitting,
                and more. By combining high-volume, serialized fulfillment with
                a range of freight management services, we offer holistic
                solutions that drive the technology industry forward.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="w3l-ab-page py-5" id="about1">
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="row align-items-center">
            <div className="col-lg-6 pe-lg-5">
              <h6 className="title-subw3hny mb-2">
                Business Trading Solutions
              </h6>
              <h3 className="title-w3l mb-2">Energy</h3>
              <p className="mt-3">
                The energy industry comprises sectors engaged in energy
                production, trade, and distribution. As energy consumption
                remains vital, our integrated solutions cater to renewable
                energy companies focused on grid parity and cost efficiency. We
                support organizations dedicated to building a sustainable future
                by partnering with them on their global endeavors.
              </p>
            </div>
            <div className="col-lg-5 offset-lg-1 text-end mt-lg-0 mt-5 position-relative">
              <img
                src="assets/images/Energy.jpg"
                alt=""
                className="img-fluid radius-image"
              />
              <div className="imginfo__box">
                <p>Energy</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="w3l-ab-page py-5" id="about1">
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="row align-items-center">
            <div className="col-lg-5 offset-lg-1 text-end mt-lg-0 mt-5 position-relative">
              <img
                src="assets/images/Healthcare.jpg"
                alt=""
                className="img-fluid radius-image"
              />
              <div className="imginfo__box">
                <p>Healthcare</p>
              </div>
            </div>{" "}
            <div className="col-lg-6 pe-lg-5">
              <h6 className="title-subw3hny mb-2">
                Business Trading Solutions
              </h6>
              <h3 className="title-w3l mb-2">Healthcare</h3>
              <p className="mt-3">
                In the ever-transforming healthcare sector, precision and
                innovation are paramount. We understand the importance of
                product quality and supply chain integrity. Our holistic
                approach aligns with the healthcare industry's demands, ensuring
                critical products' availability and unfaltering supply chain
                performance.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
