import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="inner-banner py-5">
        <section className="w3l-breadcrumb text-left py-sm-5 ">
          <div className="container">
            <div className="w3breadcrumb-gids">
              <div className="w3breadcrumb-left text-left">
                <h2 className="inner-w3-title mt-lg-5 mt-4">Rail Service </h2>
              </div>
              <div className="w3breadcrumb-right">
                <ul className="breadcrumbs-custom-path">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li className="active">
                    <span className="fas fa-arrow-right mx-2" /> Rail Service{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/*//inner-page*/}
      {/*/w3l-blog*/}
      <section className="w3l-blog blog-single-post">
        <div className="blog py-5">
          <div
            style={{
              margin: "8px auto",
              display: "block",
              textAlign: "center",
            }}
          >
            {/*-728x90-*/}
          </div>
          <div className="container py-lg-5 py-md-4 py-2">
            <div className="row justify-content-center bloghny-page">
              {/*/w3l-blopagew3-left*/}
              <div className="col-lg-8 col-12 blopagew3-left">
                <div className="card mb-4">
                  <div className="card-header p-0 position-relative">
                    <a className="zoom d-block">
                      <img
                        className="card-img-bottom d-block"
                        src="assets/images/Rail Service.jpg"
                        alt="Card image cap"
                      />
                    </a>
                  </div>
                  <div className="card-body blog-details">
                    <a className="blog-desc">Rail Service</a>
                    <p>
                      Renowned for our excellence in rail cargo services, our
                      company is a symbol of reliability and efficiency in the
                      industry. Our extensive experience in the secure
                      transportation of consignments via rail sets us apart.
                      When you opt for our services, you're selecting a
                      dedicated team of professionals who prioritize the safety
                      and integrity of your goods throughout their entire
                      journey.
                    </p>

                    <p>
                      Our commitment to utmost care and precision is at the core
                      of our rail cargo services. We recognize the value of your
                      cargo and leave no stone unturned to preserve it. With a
                      strong emphasis on safety measures and strategic handling,
                      we ensure that your goods reach their intended
                      destinations without compromise. Our dedicated experts
                      leverage their expertise to flawlessly execute each step
                      of the process, providing you with the assurance that your
                      products are in capable hands.
                    </p>

                    <p>
                      In today's age of advanced technology, monitoring your
                      cargo's progress is easier than ever. Our state-of-the-art
                      information management systems offer real-time updates on
                      your consignment's journey. You can track its movement
                      from origin to destination, ensuring transparency and
                      empowering you to make informed decisions. With our rail
                      cargo services, you not only get reliable transportation
                      but also the peace of mind that comes from staying closely
                      connected to your goods.
                    </p>

                    <p>
                      We recognize the critical importance of timing and the
                      unwavering demand for quality. Our rail cargo services are
                      meticulously designed to optimize both factors. Your
                      products will swiftly reach their target markets without
                      compromising their quality. From start to finish, we
                      prioritize efficiency, safety, and precision. By choosing
                      our rail cargo services, you're choosing a partner
                      committed to delivering your goods with the highest
                      standards of professionalism and care.
                    </p>
                  </div>
                </div>
              </div>
              {/*//w3l-blopagew3-left*/}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
