import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid hero-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="hero-header-inner animated zoomIn">
                <h1 className="display-1 text-dark">Road Freight</h1>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item text-dark" aria-current="page">
                    Road Freight
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5">
        <div className="container py-5">
          <h1
            className="display-3 mb-5 wow fadeIn text-center"
            data-wow-delay="0.1s"
          >
            {" "}
            <span className="text-primary">Road Freight</span>
          </h1>
          <div className="row g-4 justify-content-center">
            <div className="col-lg-12 col-xl-10">
              <div className="blog-item ">
                <div className="blog-img position-relative overflow-hidden">
                  <img
                    src="img/Road Freight.jpg"
                    className="img-fluid w-100"
                    alt=""
                  />
                </div>
                <div className="p-4">
                  <div className="blog-meta d-flex justify-content-between pb-2"></div>

                  <p className="mb-4">
                    <p>
                      {companyname} takes immense pride in expanding our
                      expertise to include the critical domain of land freight
                      trucking services, in addition to our well-established air
                      and ocean freight offerings. Recognizing the pivotal role
                      that precisely timed trucking plays in a comprehensive
                      logistics strategy, we have meticulously crafted a
                      framework that seamlessly integrates with our other
                      services. At {companyname}, we understand that the final
                      leg of a shipment's journey is as crucial as its initial
                      stages, and our commitment to excellence knows no
                      boundaries.
                    </p>

                    <p>
                      Embedded at the core of our operational philosophy is an
                      unwavering dedication to upholding the highest performance
                      standards within the industry. This commitment isn't a
                      mere aspiration; it's the very foundation upon which we
                      construct our services. Our extensive network of partners
                      spans the globe, ensuring that your cargo is entrusted to
                      capable hands throughout its entire journey. However, our
                      commitment doesn't stop at forming partnerships; we
                      subject our suppliers and collaborators to rigorous
                      vetting processes. By evaluating criteria such as
                      historical performance, incident records, and on-time
                      delivery history, we ensure that only the most dependable
                      and efficient partners are entrusted with your shipments.
                    </p>

                    <p>
                      What sets {companyname} apart is our holistic approach to
                      logistics. Whether it's air, ocean, or land
                      transportation, each facet seamlessly integrates into a
                      unified whole. This integration isn't merely for
                      convenience; it's a strategic advantage. Your cargo
                      doesn't traverse isolated segments; it follows a
                      meticulously planned and executed path, guided by our
                      unwavering commitment to precision and punctuality. Our
                      mission is clear: to provide you with a comprehensive
                      logistics solution that guarantees the safe and timely
                      arrival of your cargo, every time. When you choose{" "}
                      {companyname}, you're selecting a partner that not only
                      meets your needs but consistently surpasses your
                      expectations, regardless of the mode of transport.
                    </p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
