import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="inner-banner py-5">
        <section className="w3l-breadcrumb text-left py-sm-5 ">
          <div className="container">
            <div className="w3breadcrumb-gids">
              <div className="w3breadcrumb-left text-left">
                <h2 className="inner-w3-title mt-lg-5 mt-4">
                  Surface Service{" "}
                </h2>
              </div>
              <div className="w3breadcrumb-right">
                <ul className="breadcrumbs-custom-path">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li className="active">
                    <span className="fas fa-arrow-right mx-2" /> Surface Service{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/*//inner-page*/}
      {/*/w3l-blog*/}
      <section className="w3l-blog blog-single-post">
        <div className="blog py-5">
          <div
            style={{
              margin: "8px auto",
              display: "block",
              textAlign: "center",
            }}
          >
            {/*-728x90-*/}
          </div>
          <div className="container py-lg-5 py-md-4 py-2">
            <div className="row justify-content-center bloghny-page">
              {/*/w3l-blopagew3-left*/}
              <div className="col-lg-8 col-12 blopagew3-left">
                <div className="card mb-4">
                  <div className="card-header p-0 position-relative">
                    <a className="zoom d-block">
                      <img
                        className="card-img-bottom d-block"
                        src="assets/images/Surface Service.jpg"
                        alt="Card image cap"
                      />
                    </a>
                  </div>
                  <div className="card-body blog-details">
                    <a className="blog-desc">Surface Service</a>
                    <p>
                      As the acknowledged leader in the industry, we take
                      immense pride in our capacity to fulfill any
                      transportation demand throughout the country with our
                      swift, effective, and express logistics services. Our
                      reputation is founded on a commitment to deliver
                      exceptional solutions that consistently surpass
                      expectations.
                    </p>

                    <p>
                      Our unwavering dedication to meeting our clients'
                      requirements propels us to offer outstanding surface
                      services seamlessly coordinated via a dedicated fleet of
                      trucks. Our proficiency in surface services has
                      established us as a prominent figure in the field,
                      ensuring the secure and reliable transport of goods from
                      origin to destination. We place a high priority on
                      transparency, and our advanced track & trace system
                      empowers clients with real-time insights into their
                      consignments' movements.
                    </p>

                    <p>
                      With an extensive fleet of containerized trucks at our
                      disposal, we are fully prepared to handle even the most
                      delicate and perishable cargo. Our tamper-proof transit
                      solutions are complemented by straightforward
                      documentation procedures, ensuring a smooth voyage for
                      your shipments. Whether your destination is nearby or
                      across the nation, our surface services pledge dependable,
                      punctual, and secure deliveries.
                    </p>

                    <p>
                      At the heart of our services lies an unwavering commitment
                      to quality, efficiency, and client satisfaction. Our
                      express logistics solutions are designed to convert
                      transportation into a seamless experience, delivering not
                      only goods but also peace of mind to our esteemed clients.
                    </p>
                  </div>
                </div>
              </div>
              {/*//w3l-blopagew3-left*/}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
