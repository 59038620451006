import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Contact() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />

      {/*/inner-page*/}
      <div className="inner-banner py-5">
        <section className="w3l-breadcrumb text-left py-sm-5 ">
          <div className="container">
            <div className="w3breadcrumb-gids">
              <div className="w3breadcrumb-left text-left">
                <h2 className="inner-w3-title mt-lg-5 mt-4">Contact Us </h2>
              </div>
              <div className="w3breadcrumb-right">
                <ul className="breadcrumbs-custom-path">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li className="active">
                    <span className="fas fa-arrow-right mx-2" /> Contact
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/*//inner-page*/}
      {/* contacts-5-grid */}
      <div className="w3l-contact-10 py-5" id="contact">
        <div className="form-41-mian pt-md-5 pt-3">
          <div className="container">
            <div
              style={{
                margin: "8px auto",
                display: "block",
                textAlign: "center",
              }}
            >
              {/*-728x90-*/}
            </div>
            <div className="row contacts-5-grid-main mb-5">
              <div className="col-lg-6 contacts-5-grid pe-lg-5">
                <h5 className="title-subw3hny">Contact our team</h5>
                <h3 className="title-w3l mb-3">Get in touch with us</h3>
                <p>
                  Our contact form is designed for your convenience. Fill it out
                  in minutes and get ready for swift, reliable freight
                  solutions.
                </p>
                <h4 className="title-w3l-adinf mt-4">Address Info</h4>
                <div className="address-grid d-flex">
                  <i className="fas fa-map-marker-alt mt-2" />
                  <p>{companyaddress}</p>
                </div>
                <h4 className="title-w3l-adinf mt-4">Support Center 24/7</h4>
                <div className="address-grid d-flex">
                  <i className="fas fa-headset mt-2" />
                  <p>{companynumber}</p>
                </div>
                <div className="address-grid d-flex">
                  <i className="fas fa-envelope" />
                  <p className="mt-0">{companyemail}</p>
                </div>
              </div>
              <div className="col-lg-6 form-inner-cont mt-lg-0 mt-4">
                <form
                  id="contactForm"
                  action="/php/thankyou-contact.php "
                  method="post"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          className="form-control"
                          onChange={(event) => handleFnameChange(event)}
                          type="text"
                          id="fname"
                          name="fname"
                          value={fname}
                          placeholder="Enter your first name"
                          required
                        />
                        <label htmlFor="name">Enter your first name</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          className="form-control"
                          onChange={(event) => handlelnameChange(event)}
                          type="text"
                          id="lname"
                          name="lname"
                          value={lname}
                          placeholder="Enter your last name"
                          required
                        />
                        <label htmlFor="email">Enter your last name</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <input
                          className="form-control"
                          onChange={(event) => handleemailChange(event)}
                          type="email"
                          id="email"
                          name="email"
                          value={email}
                          placeholder="Enter your Email"
                          required
                        />
                        <label htmlFor="subject">Enter your Email</label>
                      </div>
                    </div>{" "}
                    <div className="col-12">
                      <div className="form-floating">
                        <input
                          className="form-control"
                          onChange={(event) => handlesubjectChange(event)}
                          type="text"
                          id="subject"
                          name="subject"
                          value={subject}
                          placeholder="Enter subject"
                          required
                        />
                        <label htmlFor="subject">Subject</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <textarea
                          className="form-control"
                          id="message"
                          name="message"
                          rows={1}
                          placeholder="Enter Message"
                          defaultValue={""}
                          value={message}
                          onChange={(event) => handlemessageChange(event)}
                        />
                        <label htmlFor="message">Message</label>
                      </div>
                    </div>
                    <div className="col-12 text-center">
                      <button
                        className="btn btn-primary py-3 px-5"
                        type="submit"
                        defaultValue=""
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
                <h5 style={{ color: "red" }}>{result}</h5>
              </div>
            </div>
          </div>
        </div>
        {/* //contacts-5-grid */}
      </div>
      {/* /map*/}
      <div className="contacts-sub-5">
        <div className="contacts-mapw3 position-relative">
         
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.9602783350133!2d72.99922817520509!3d19.06548398213686!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c116c44b2e41%3A0xf25d6f87755145ba!2sHaware%20Fantasia%20Business%20Park!5e0!3m2!1sen!2sin!4v1695627975885!5m2!1sen!2sin"
            style={{ border: 0 }}
            allowFullScreen
          />
        </div>
      </div>
      {/* //map*/}
      <Footer />
    </>
  );
}
