import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="inner-banner py-5">
        <section className="w3l-breadcrumb text-left py-sm-5 ">
          <div className="container">
            <div className="w3breadcrumb-gids">
              <div className="w3breadcrumb-left text-left">
                <h2 className="inner-w3-title mt-lg-5 mt-4">
                  Warehouse Service{" "}
                </h2>
              </div>
              <div className="w3breadcrumb-right">
                <ul className="breadcrumbs-custom-path">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li className="active">
                    <span className="fas fa-arrow-right mx-2" /> Warehouse
                    Service{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/*//inner-page*/}
      {/*/w3l-blog*/}
      <section className="w3l-blog blog-single-post">
        <div className="blog py-5">
          <div
            style={{
              margin: "8px auto",
              display: "block",
              textAlign: "center",
            }}
          >
            {/*-728x90-*/}
          </div>
          <div className="container py-lg-5 py-md-4 py-2">
            <div className="row justify-content-center bloghny-page">
              {/*/w3l-blopagew3-left*/}
              <div className="col-lg-8 col-12 blopagew3-left">
                <div className="card mb-4">
                  <div className="card-header p-0 position-relative">
                    <a className="zoom d-block">
                      <img
                        className="card-img-bottom d-block"
                        src="assets/images/Warehouse Service.jpg"
                        alt="Card image cap"
                      />
                    </a>
                  </div>
                  <div className="card-body blog-details">
                    <a className="blog-desc">Warehouse Service</a>
                    <p>
                      Experience the potential of small packages that can have
                      monumental impacts. Our Warehouse and Distribution Network
                      Services are meticulously crafted to transform your supply
                      chain management. Through the seamless integration of pool
                      distribution, cross-docking, warehousing, and
                      transportation solutions, we offer a comprehensive
                      approach to elevate your business operations. With a
                      nationwide presence, we oversee more than 15 million sq.
                      ft. of strategically positioned warehouse space for
                      optimal coverage.
                    </p>

                    <p>
                      Our warehouse services go beyond the ordinary, providing
                      tailor-made, integrated transportation solutions that are
                      unmatched in the industry. Within our warehouse
                      infrastructure, you'll discover a dynamic mix of
                      purpose-built, dedicated, and multi-client facilities. Our
                      operations follow a well-structured process flow for each
                      sub-operation, ensuring efficiency and precision
                      throughout. Whether it's route optimization or tracking
                      key metrics, our commitment to quality is embedded in
                      every aspect of our service.
                    </p>

                    <p>
                      Flexibility is a fundamental aspect of our Warehouse and
                      Distribution Network Services. We offer both short-term
                      and long-term solutions, with the ability to customize
                      strategies to suit your unique requirements. As a
                      testament to our dedication to innovation, we've
                      introduced a collaborative delivery system that leverages
                      shared routes. This groundbreaking approach significantly
                      reduces transportation costs, with the resulting savings
                      directly benefiting you, our esteemed clients. Our growth
                      journey continues as we embark on the development of
                      world-class warehousing facilities strategically situated
                      in key industrial hubs across India.
                    </p>

                    <p>
                      Embrace a new era in supply chain management with our
                      Warehouse and Distribution Network Services. Allow us to
                      optimize your logistics, enhance efficiency, and open the
                      door to unprecedented savings. As we expand our presence,
                      we remain committed to delivering excellence at every
                      stage, ensuring your business thrives in the competitive
                      landscape.
                    </p>
                  </div>
                </div>
              </div>
              {/*//w3l-blopagew3-left*/}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
