import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="inner-banner py-5">
        <section className="w3l-breadcrumb text-left py-sm-5 ">
          <div className="container">
            <div className="w3breadcrumb-gids">
              <div className="w3breadcrumb-left text-left">
                <h2 className="inner-w3-title mt-lg-5 mt-4">Air Service </h2>
              </div>
              <div className="w3breadcrumb-right">
                <ul className="breadcrumbs-custom-path">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li className="active">
                    <span className="fas fa-arrow-right mx-2" /> Air Service{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/*//inner-page*/}
      {/*/w3l-blog*/}
      <section className="w3l-blog blog-single-post">
        <div className="blog py-5">
          <div
            style={{
              margin: "8px auto",
              display: "block",
              textAlign: "center",
            }}
          >
            {/*-728x90-*/}
          </div>
          <div className="container py-lg-5 py-md-4 py-2">
            <div className="row justify-content-center bloghny-page">
              {/*/w3l-blopagew3-left*/}
              <div className="col-lg-8 col-12 blopagew3-left">
                <div className="card mb-4">
                  <div className="card-header p-0 position-relative">
                    <a className="zoom d-block">
                      <img
                        className="card-img-bottom d-block"
                        src="assets/images/Air Service.jpg"
                        alt="Card image cap"
                      />
                    </a>
                  </div>
                  <div className="card-body blog-details">
                    <a className="blog-desc">Air Service</a>
                    <p>
                      For all your international air freight needs, you've found
                      the experts – we are your ultimate destination for
                      efficient and timely logistics solutions. As an
                      experienced international freight forwarder, we are your
                      steadfast partner, ready to fulfill all your air freight
                      requirements.
                    </p>

                    <p>
                      Our extensive knowledge and expertise in international air
                      freight distinguish us as the preferred choice for
                      businesses seeking fast and effective logistics solutions.
                      With a deep understanding of the intricacies of global
                      shipments, we are dedicated to ensuring the prompt and
                      secure delivery of your cargo to its destination.
                    </p>

                    <p>
                      As your committed air freight collaborator, we take on the
                      responsibility of seamlessly managing your international
                      shipments. From handling documentation to tracking, we
                      simplify the process, allowing you to concentrate on your
                      core business. With a global network of partners and
                      abundant resources, we assure you a hassle-free and
                      dependable air freight experience, every time.
                    </p>

                    <p>
                      Rest assured, when you select us as your international air
                      freight specialists, you are opting for a trustworthy,
                      efficient, and customer-focused approach that guarantees
                      the timely and flawless delivery of your cargo. Your
                      satisfaction is our foremost priority, and we are here to
                      provide you with the smooth logistics solutions you
                      deserve.
                    </p>
                  </div>
                </div>
              </div>
              {/*//w3l-blopagew3-left*/}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
