import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="inner-banner py-5">
        <section className="w3l-breadcrumb text-left py-sm-5 ">
          <div className="container">
            <div className="w3breadcrumb-gids">
              <div className="w3breadcrumb-left text-left">
                <h2 className="inner-w3-title mt-lg-5 mt-4">Ocean Service </h2>
              </div>
              <div className="w3breadcrumb-right">
                <ul className="breadcrumbs-custom-path">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li className="active">
                    <span className="fas fa-arrow-right mx-2" /> Ocean Service{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/*//inner-page*/}
      {/*/w3l-blog*/}
      <section className="w3l-blog blog-single-post">
        <div className="blog py-5">
          <div
            style={{
              margin: "8px auto",
              display: "block",
              textAlign: "center",
            }}
          >
            {/*-728x90-*/}
          </div>
          <div className="container py-lg-5 py-md-4 py-2">
            <div className="row justify-content-center bloghny-page">
              {/*/w3l-blopagew3-left*/}
              <div className="col-lg-8 col-12 blopagew3-left">
                <div className="card mb-4">
                  <div className="card-header p-0 position-relative">
                    <a className="zoom d-block">
                      <img
                        className="card-img-bottom d-block"
                        src="assets/images/Ocean Service.jpg"
                        alt="Card image cap"
                      />
                    </a>
                  </div>
                  <div className="card-body blog-details">
                    <a className="blog-desc">Ocean Service</a>
                    <p>
                      Embark on a seamless maritime voyage with our ocean
                      freight proficiency, where efficiency and dependability
                      combine to create a smooth shipping encounter. As seasoned
                      experts in the ocean freight domain, we are your trusted
                      companions for all your maritime logistics necessities.
                    </p>

                    <p>
                      Our profound understanding of ocean freight operations
                      equips us to navigate the intricacies of global shipping
                      effortlessly. Whether you seek to convey goods across
                      continents or navigate intricate trade routes, we possess
                      the competence to ensure your cargo's secure and punctual
                      arrival at its destination.
                    </p>

                    <p>
                      At the core of our ocean freight services is a commitment
                      to personalized solutions that cater to your distinct
                      requirements. We comprehend that every cargo is distinct,
                      and we offer an array of choices, ranging from
                      full-container loads (FCL) to less-than-container loads
                      (LCL), to accommodate your demands. Our associations with
                      prominent carriers and shipping lines empower us to
                      furnish adaptable, cost-effective, and trustworthy
                      maritime solutions.
                    </p>

                    <p>
                      As your dedicated ocean freight associate, we surpass the
                      mere transportation process. We extend comprehensive
                      support, encompassing everything from documentation to
                      tracking, ensuring transparency and tranquility throughout
                      the expedition. With our extensive global network and
                      unwavering dedication, your cargo is in reliable hands,
                      and your ocean freight experience is streamlined and
                      effective.
                    </p>
                  </div>
                </div>
              </div>
              {/*//w3l-blopagew3-left*/}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
