import { useState } from "react";
import { useHistory } from "react-router-dom";
import Getquote from "../pages/Getquote";

import $ from "jquery";
import Header from "../components/Header";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  const [subemail, setSubemail] = useState("");
  const [result, setResult] = useState("");

  const handleSubemailChange = (e) => {
    setSubemail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      {/*/w3l-newsletter*/}
      <section className="w3l-newsletter">
        <div className="container">
          <div className="uphny-w3l-newsletter-inf">
            <div className="row uphny-w3l-newsletter align-items-center">
              <div className="col-lg-6 w3l-newsletter-left text-left">
                <h3 className="titlehny">
                  Subscribe to Newsletter
                </h3>
              </div>
              <div className="col-lg-6 w3l-newsletter-form ps-lg-5 mt-lg-0 mt-md-5 mt-4">
              <form
                      className="subscribe-wthree"
                      action="/php/thankyou-subscribe.php "
                      method="POST"
                      onSubmit={(event) => handleSubmit(event)}
                    >
                      <div className="flex-wrap subscribe-wthree-field">
                        <input
                          onChange={(event) => handleSubemailChange(event)}
                          id="subemail"
                          value={subemail}
                          type="email"
                          name="subemail"
                          placeholder="Enter your email address"
                          required
                          className="form-control"
                        />
                        <button
                          type="submit"
                          className="btn btn-style btn-primary"
                        >
                         Subscribe
                        </button>
                      </div>
                    </form>
                    <h5 style={{ color: "red" }}>{result}</h5>

              </div>
            </div>
          </div>
        </div>
      </section>
      {/*//w3l-newsletter*/}
      <footer className="w3l-footer">
        <div className="w3l-footer-16 py-5">
          <div className="container py-md-5 py-3 pb-0">
            <div className="row footer-p right-side">
              <div className="col-lg-4 col-md-6 pe-lg-5">
                <h2 className="footerw3l-logo">
                  <Link className="navbar-brand" to="index.html">
                    <span className="w3yellow">{companyname}</span>
                  </Link>
                </h2>
                <p className="mt-3">
                  At Maxworks International, we specialize in providing
                  exceptional solutions in freight forwarding and export
                  services, enabling your business expansion to reach every
                  corner of your ambitions. Our approach is rooted in a deep
                  understanding of your internal and external needs, coupled
                  with a strategic perspective that offers a comprehensive
                  solution to propel your brand forward.
                </p>
              </div>
              <div className="col-lg-5 col-md-6 mt-lg-0 mt-5">
                <div className="row">
                  <div className=" col-6 column">
                    <h3>Links</h3>
                    <ul className="footer-gd-16">
                      <li>
                        {" "}
                        <Link to="/Home">Home</Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/About">About Us</Link>
                      </li>

                      <li>
                        {" "}
                        <Link to="/Trading">Trading Services</Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/Contact">Contact</Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/Getquote">Request For Quote</Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/Privacy">Privacy Policy</Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/Terms">Terms</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-6 column">
                    <h3>Services</h3>
                    <ul className="footer-gd-16">
                      <li>
                        <Link to="/Ocean">Ocean Service</Link>
                      </li>
                      <li>
                        <Link to="/Air">Air Service</Link>
                      </li>
                      <li>
                        <Link to="/Rail">Rail Service</Link>
                      </li>
                      <li>
                        <Link to="/Surface">Surface Service</Link>
                      </li>
                      <li>
                        <Link to="/Warehouse">Warehouse Service</Link>
                      </li>{" "}
                      <li>
                        <Link to="/Door">Door to Door Logistics</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 pe-lg-5">
                <h2 className="footerw3l-logo">
                  <Link className="navbar-brand">Contact</Link>
                </h2>
                <p className="mt-3">
                  <strong>Address: </strong>
                  {companyaddress} <br />
                  <br />
                  <strong>Phone: </strong>
                  {companynumber} <br />
                  <br />
                  <strong>Email: </strong>
                  {companyemail} <br />
                </p>
              </div>
            </div>
            <div className="below-section text-center pt-lg-4 mt-5">
        <p className="copy-text">© 2023 {companyname}. All rights reserved.   </p>
        <ul className="footer-gd-16 d-flex justify-content-center">
          <li><Link to="/Terms">Terms
            </Link></li>
          <li className="mx-2">/
          </li>
          <li><Link to="/Privacy">Privacy Policy</Link></li>
        </ul>
      </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
