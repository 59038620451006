import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            Customs Clearance
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Customs Clearance
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <h1 className="display-6 mb-5 text-center">
                  Customs Clearance
                </h1>
                <div className="col-lg-12">
                  <div className=" g-3 text-center pb-4">
                    <img
                      className="img-fluid w-75 wow zoomIn"
                      data-wow-delay="0.7s"
                      src="img/Customs Clearance.jpg"
                    />
                  </div>
                </div>

                <p className="mb-4">
                  <p>
                    Integrated with the customs framework, we adeptly document
                    all necessary procedures directly from our offices, ensuring
                    a streamlined and expeditious process. Our comprehensive
                    support encompasses a range of steps within the clearance
                    procedure, encompassing:
                  </p>

                  <ul>
                    <li>
                      Accurate determination of customs tariffs corresponding to
                      item descriptions.
                    </li>
                    <li>Facilitation of the Import License preparation.</li>
                    <li>
                      Expertise in compiling specialized customs authorizations
                      as required.
                    </li>
                    <li>
                      Skillful arrangement and submission of the customs
                      declaration.
                    </li>
                    <li>
                      Assisting throughout customs inspections to ensure
                      compliance.
                    </li>
                    <li>
                      Provision of comprehensive goods insurance coverage.
                    </li>
                    <li>
                      Efficient coordination of both goods pickup and delivery.
                    </li>
                  </ul>

                  <p>
                    Our interconnected approach, bolstered by our technological
                    integration with the customs system, guarantees a seamless
                    and efficient navigation of the intricate customs landscape.
                    We are your reliable partner, dedicated to ensuring a smooth
                    and hassle-free experience throughout the entire customs
                    clearance process.
                  </p>
                </p>
                <div className="border-top mt-4 pt-4">
                  <div className="row"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}

      <Footer />
    </>
  );
}
