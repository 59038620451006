import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  const [uname, setUname] = useState("");
  const [uemail, setUemail] = useState("");
  const [uphone, setUphone] = useState("");
  const [subject, setSubject] = useState("");
  const [uservice, setUservice] = useState("");

  const [umessage, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleUnameChange = (e) => {
    setUname(e.target.value);
  };
  const handleUemailChange = (e) => {
    setUemail(e.target.value);
  };
  const handleUphoneChange = (e) => {
    setUphone(e.target.value);
  };
  const handleuserviceChange = (e) => {
    setUservice(e.target.value);
  };

  const handlesubjectChange = (e) => {};

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />
      {/* main-slider */}
      <section style={{paddingTop:"50px"}}
        className="w3l-main-slider banner-slider w3-banner jarallax"
        id="home"
      >
        <video autoPlay muted loop>
          <source src="assets/images/video.mp4"></source>
        </video>
      </section>
      {/* //main-slider */}
      {/*/grids*/}
      <section className="w3l-grids-3 " id="about">
        <div className="container py-md-5 py-3">
          <div className="row w3home-ab-grids">
            <div className="w3ab-right-top col-lg-6 pe-lg-5 pt-2 mt-4">
              <img
                src="assets/images/About.jpg"
                alt=""
                className="img-fluid radius-image"
              />
            </div>
            <div className="w3ab-left-top col-lg-6 mt-lg-0 mt-4 pt-lg-0 pt-2">
              <h6 className="title-subw3hny mb-1">About us</h6>
              <h3 className="title-w3l mb-2">Welcome to {companyname}.</h3>
              <p className="my-3">
                At {companyname}, we specialize in providing exceptional
                solutions in freight forwarding and export services, enabling
                your business expansion to reach every corner of your ambitions.
                Our approach is rooted in a deep understanding of your internal
                and external needs, coupled with a strategic perspective that
                offers a comprehensive solution to propel your brand forward. We
                prioritize operational excellence and offer practical,
                cost-effective, and technology-driven solutions to address even
                the most complex logistics and supply chain management
                challenges.
              </p>
              <Link to="/About" className="btn btn-style btn-primary mt-4">
                Read More
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/*//grids*/}
      {/*/w3l-blog*/}
      <section className="w3l-blog">
        <div className="blog py-5" id="Newsblog">
          <div className="container py-lg-5 py-md-4 py-2">
            <div className="title-content text-center mb-lg-3 mb-4">
              <h6 className="title-subw3hny mb-1">Our Services</h6>
              <h3 className="title-w3l mb-5">What We Do</h3>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 item">
                <div className="card">
                  <div className="card-header p-0 position-relative">
                    <Link to="/Ocean" className="zoom d-block">
                      <img
                        className="card-img-bottom d-block"
                        src="assets/images/Ocean Service.jpg"
                        alt="Card image cap"
                      />
                    </Link>
                  </div>
                  <div className="card-body blog-details">
                    <div className="author align-items-center">
                      <div className="blog_item_date">
                        <h5>Services</h5>
                      </div>
                    </div>
                    <Link to="/Ocean" className="blog-desc">
                      Ocean Service
                    </Link>
                    <p>
                      Embark on a seamless maritime voyage with our ocean
                      freight proficiency, where efficiency and dependability
                      combine to create a smooth shipping encounter.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 item">
                <div className="card">
                  <div className="card-header p-0 position-relative">
                    <Link to="/Air" className="zoom d-block">
                      <img
                        className="card-img-bottom d-block"
                        src="assets/images/Air Service.jpg"
                        alt="Card image cap"
                      />
                    </Link>
                  </div>
                  <div className="card-body blog-details">
                    <div className="author align-items-center">
                      <div className="blog_item_date">
                        <h5>Services</h5>
                      </div>
                    </div>
                    <Link to="/Air" className="blog-desc">
                      Air Service
                    </Link>
                    <p>
                      For all your international air freight needs, you've found
                      the experts – we are your ultimate destination for
                      efficient and timely logistics solutions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 item">
                <div className="card">
                  <div className="card-header p-0 position-relative">
                    <Link to="/Rail" className="zoom d-block">
                      <img
                        className="card-img-bottom d-block"
                        src="assets/images/Rail Service.jpg"
                        alt="Card image cap"
                      />
                    </Link>
                  </div>
                  <div className="card-body blog-details">
                    <div className="author align-items-center">
                      <div className="blog_item_date">
                        <h5>Services</h5>
                      </div>
                    </div>
                    <Link to="/Rail" className="blog-desc">
                      Rail Service
                    </Link>
                    <p>
                      Renowned for our excellence in rail cargo services, our
                      company is a symbol of reliability and efficiency in the
                      industry. Our extensive experience in the secure
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 item">
                <div className="card">
                  <div className="card-header p-0 position-relative">
                    <Link to="/Surface" className="zoom d-block">
                      <img
                        className="card-img-bottom d-block"
                        src="assets/images/Surface Service.jpg"
                        alt="Card image cap"
                      />
                    </Link>
                  </div>
                  <div className="card-body blog-details">
                    <div className="author align-items-center">
                      <div className="blog_item_date">
                        <h5>Services</h5>
                      </div>
                    </div>
                    <Link to="/Surface" className="blog-desc">
                      Surface Service
                    </Link>
                    <p>
                      As the acknowledged leader in the industry, we take
                      immense pride in our capacity to fulfill any
                      transportation demand throughout the country with our
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 item">
                <div className="card">
                  <div className="card-header p-0 position-relative">
                    <Link to="/Warehouse" className="zoom d-block">
                      <img
                        className="card-img-bottom d-block"
                        src="assets/images/Warehouse Service.jpg"
                        alt="Card image cap"
                      />
                    </Link>
                  </div>
                  <div className="card-body blog-details">
                    <div className="author align-items-center">
                      <div className="blog_item_date">
                        <h5>Services</h5>
                      </div>
                    </div>
                    <Link to="/Warehouse" className="blog-desc">
                      Warehouse Service
                    </Link>
                    <p>
                      Experience the potential of small packages that can have
                      monumental impacts. Our Warehouse and Distribution Network
                      Services are meticulously crafted to transform your supply
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 item">
                <div className="card">
                  <div className="card-header p-0 position-relative">
                    <Link to="/Door" className="zoom d-block">
                      <img
                        className="card-img-bottom d-block"
                        src="assets/images/Door to Door Logistics.jpg"
                        alt="Card image cap"
                      />
                    </Link>
                  </div>
                  <div className="card-body blog-details">
                    <div className="author align-items-center">
                      <div className="blog_item_date">
                        <h5>Services</h5>
                      </div>
                    </div>
                    <Link to="/Door" className="blog-desc">
                      Door to Door Logistics
                    </Link>
                    <p>
                      At {companyname}, we take great pride in our meticulous
                      cargo monitoring process, ensuring that your shipment is
                      under our vigilant care
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*//w3l-blog*/}
      {/* features-section */}
      <section className="w3l-features py-5" id="features">
        <div className="container">
          <div className="main-cont-wthree-2">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6 mt-lg-5 mt-4">
                <div className="grids-1 box-wrap">
                  <div className="icon">
                    <i className="fas fa-eye" />
                  </div>
                  <h4>
                    <Link className="title-head mb-3">Our Vision</Link>
                  </h4>
                  <p className="text-para">
                    Our vision is to emerge as a market-leading organization
                    recognized for excellence in maritime services, logistics
                    solutions, and international port operations.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 mt-lg-5 mt-4">
                <div className="grids-1 box-wrap">
                  <div className="icon">
                    <i className="fas fa-bullseye" />
                  </div>
                  <h4>
                    <Link className="title-head mb-3">Our Mission</Link>
                  </h4>
                  <p className="text-para">
                    We provide exceptional maritime services, comprehensive
                    logistics solutions, and state-of-the-art port terminals.
                    Our commitment to continuous improvement and maintaining a
                    secure environment ensures that we are the preferred choice
                    for our clients.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*//features-section */}
      <div className=" w3l-3-grids py-5" id="grids-3">
        <div className="container py-md-4">
          <div className="title-content text-center mb-lg-3 mb-4">
            <h6 className="title-subw3hny mb-1">Trading Solutions</h6>
            <h3 className="title-w3l">Our Business Trading Solutions </h3>
          </div>
          <div className="row">
            <div className="col-md-4 mt-md-0">
              <div className="grids3-info position-relative">
                <Link to="/Trading" className="d-block zoom">
                  <img
                    src="assets/images/Fashion.jpg"
                    alt=""
                    className="img-fluid news-image"
                  />
                </Link>
                <div className="w3-grids3-info">
                  <h4 className="gdnhy-1">
                    <Link to="/Trading">Fashion</Link>
                    <Link
                      className="w3item-link btn btn-style mt-4"
                      to="/Trading"
                    >
                      Read More
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-md-0">
              <div className="grids3-info position-relative">
                <Link to="/Trading" className="d-block zoom">
                  <img
                    src="assets/images/Consumer & Retail.jpg"
                    alt=""
                    className="img-fluid news-image"
                  />
                </Link>
                <div className="w3-grids3-info">
                  <h4 className="gdnhy-1">
                    <Link to="/Trading">Consumer & Retail</Link>
                    <Link
                      className="w3item-link btn btn-style mt-4"
                      to="/Trading"
                    >
                      Read More
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-md-0">
              <div className="grids3-info position-relative">
                <Link to="/Trading" className="d-block zoom">
                  <img
                    src="assets/images/Automotive.jpg"
                    alt=""
                    className="img-fluid news-image"
                  />
                </Link>
                <div className="w3-grids3-info">
                  <h4 className="gdnhy-1">
                    <Link to="/Trading">Automotive</Link>
                    <Link
                      className="w3item-link btn btn-style mt-4"
                      to="/Trading"
                    >
                      Read More
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container py-md-4">
          <div className="row">
            <div className="col-md-4 mt-md-0">
              <div className="grids3-info position-relative">
                <Link to="/Trading" className="d-block zoom">
                  <img
                    src="assets/images/Technology.jpg"
                    alt=""
                    className="img-fluid news-image"
                  />
                </Link>
                <div className="w3-grids3-info">
                  <h4 className="gdnhy-1">
                    <Link to="/Trading">Technology</Link>
                    <Link
                      className="w3item-link btn btn-style mt-4"
                      to="/Trading"
                    >
                      Read More
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-md-0">
              <div className="grids3-info position-relative">
                <Link to="/Trading" className="d-block zoom">
                  <img
                    src="assets/images/Energy.jpg"
                    alt=""
                    className="img-fluid news-image"
                  />
                </Link>
                <div className="w3-grids3-info">
                  <h4 className="gdnhy-1">
                    <Link to="/Trading">Energy</Link>
                    <Link
                      className="w3item-link btn btn-style mt-4"
                      to="/Trading"
                    >
                      Read More
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-md-0">
              <div className="grids3-info position-relative">
                <Link to="/Trading" className="d-block zoom">
                  <img
                    src="assets/images/Healthcare.jpg"
                    alt=""
                    className="img-fluid news-image"
                  />
                </Link>
                <div className="w3-grids3-info">
                  <h4 className="gdnhy-1">
                    <Link to="/Trading">Healthcare</Link>
                    <Link
                      className="w3item-link btn btn-style mt-4"
                      to="/Trading"
                    >
                      Read More
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*//bottom-3-grids*/}
      <div
        style={{ margin: "8px auto", display: "block", textAlign: "center" }}
      ></div>

      <Footer />
    </>
  );
}
