import { Outlet, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = (
  {
    /*  useEffect(() => {
    // Add event listener to disable right-click
    document.addEventListener('contextmenu', handleRightClick);
    
    // Cleanup: Remove event listener when component unmounts
    return () => {
      document.removeEventListener('contextmenu', handleRightClick);
    };
  }, []);

  // Function to handle the right-click event
  const handleRightClick = (e) => {
    e.preventDefault(); // Prevent the default context menu
  };
*/
  }
) => {
  return (
    <>
      {/*/Header*/}
      <header id="site-header" className="fixed-top">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light stroke py-lg-0" style={{background:"#fff"}}>
            <h1>
              <Link className="navbar-brand" to="/Home">
                <img
                  src="assets/images/logo.png"
                  style={{ height: "120px" }}
                ></img>
              </Link>
            </h1>
            <button
              className="navbar-toggler collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarScroll"
              aria-controls="navbarScroll"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon fa icon-expand fa-bars" />
              <span className="navbar-toggler-icon fa icon-close fa-times" />
            </button>
            <div className="collapse navbar-collapse" id="navbarScroll">
              <ul className="navbar-nav mx-lg-auto my-2 my-lg-0 navbar-nav-scroll">
                <li className="nav-item">
                  <Link
                    className="nav-link active"
                    aria-current="page"
                    to="/Home"
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/About">
                    Who We Are
                  </Link>
                </li>
               
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    id="navbarScrollingDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    What We Do <i className="fas fa-angle-down" />
                  </Link>
                  <ul
                    className="dropdown-menu dropdown-menu-2"
                    aria-labelledby="navbarScrollingDropdown"
                  >
                    <li>
                      <Link className="dropdown-item pt-2" to="/Ocean">
                      Ocean Service
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item pt-2" to="/Air">
                      Air Service
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item pt-2" to="/Rail">
                      Rail Service
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item pt-2" to="/Surface">
                      Surface Service
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item pt-2" to="/Warehouse">
                      Warehouse Service
                      </Link>
                    </li> <li>
                      <Link className="dropdown-item pt-2" to="/Door">
                      Door to Door Logistics
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#Pages"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Business Trading Solutions <span className="fa fa-angle-down ms-1" />
                  </Link>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarScrollingDropdown"
                  >
                    <li>
                      <Link className="dropdown-item" to="/Trading">
                      Fashion
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Trading">
                      Consumer & Retail
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Trading">
                      Automotive
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Trading">
                      Technology
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Trading">
                      Energy
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Trading">
                      Healthcare
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/Contact">
                    Contact
                  </Link>
                </li>
              </ul>
              {/*/search-right*/}
              <ul className="header-search me-lg-4">
                <li className="nav-item search-right">
                  <Link
                    to="/Getquote"
                    className="btn search-btn btn-primary"
                    title="search"
                  >
                    Place A Quote
                  </Link>
                 
                </li>
              </ul>
              {/*//search-right*/}
            </div>
           
          </nav>
        </div>
      </header>
      {/*//Header*/}
    </>
  );
};

export default Header;
