import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            Project Cargo
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Project Cargo
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <h1 className="display-6 mb-5 text-center">Project Cargo</h1>
                <div className="col-lg-12">
                  <div className=" g-3 text-center pb-4">
                    <img
                      className="img-fluid w-75 wow zoomIn"
                      data-wow-delay="0.7s"
                      src="img/Project Cargo.jpg"
                    />
                  </div>
                </div>

                <p className="mb-4">
                  <p>
                    Harnessing our specialized expertise and extensive know-how,
                    we commit ourselves wholeheartedly to delivering optimal
                    solutions tailored for handling hefty and unconventional
                    cargo. Navigating the intricacies of out-of-gauge shipments
                    with finesse, we draw upon our comprehensive understanding
                    to ensure seamless operations. It's not just about managing
                    oversized loads; it's about orchestrating a harmonious
                    symphony of precision and logistics.
                  </p>

                  <p>
                    With a keen eye on detail and an arsenal of proficiency, we
                    go beyond the ordinary to ensure that every aspect of
                    transporting these exceptional cargoes is meticulously
                    planned and flawlessly executed. Our approach extends to
                    encompassing the provision of dedicated equipment precisely
                    suited for the task at hand. Whether it's specialized
                    cranes, custom-built transporters, or innovative loading
                    techniques, we leave no stone unturned in our quest to
                    ensure the smoothest journey possible for your valuable
                    cargo.
                  </p>

                  <p>
                    Our commitment doesn't just lie in the physical handling of
                    the cargo; it's a holistic dedication to meeting the unique
                    demands and challenges posed by these extraordinary
                    shipments. By seamlessly merging expertise, technology, and
                    a finely tuned strategy, we endeavor to provide a seamless
                    and efficient experience that instills confidence and trust.
                    The world of heavy and out-of-gauge cargo may be complex,
                    but with our unwavering dedication, it becomes a realm of
                    possibilities waiting to be explored.
                  </p>
                </p>
                <div className="border-top mt-4 pt-4">
                  <div className="row"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}

      <Footer />
    </>
  );
}
