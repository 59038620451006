import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="inner-banner py-5">
        <section className="w3l-breadcrumb text-left py-sm-5 ">
          <div className="container">
            <div className="w3breadcrumb-gids">
              <div className="w3breadcrumb-left text-left">
                <h2 className="inner-w3-title mt-lg-5 mt-4">About Us </h2>
              </div>
              <div className="w3breadcrumb-right">
                <ul className="breadcrumbs-custom-path">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li className="active">
                    <span className="fas fa-arrow-right mx-2" /> About Us{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="w3l-grids-3 " id="about">
        <div className="container py-md-5 py-3">
          <div className="row w3home-ab-grids">
            <div className="w3ab-right-top col-lg-6 pe-lg-5 pt-2 mt-4">
              <img
                src="assets/images/About.jpg"
                alt=""
                className="img-fluid radius-image"
              />
            </div>
            <div className="w3ab-left-top col-lg-6 mt-lg-0 mt-4 pt-lg-0 pt-2">
              <h6 className="title-subw3hny mb-1">About us</h6>
              <h3 className="title-w3l mb-2">Welcome to {companyname}.</h3>
              <p className="my-3">
                At {companyname}, we specialize in providing exceptional
                solutions in freight forwarding and export services, enabling
                your business expansion to reach every corner of your ambitions.
                Our approach is rooted in a deep understanding of your internal
                and external needs, coupled with a strategic perspective that
                offers a comprehensive solution to propel your brand forward. We
                prioritize operational excellence and offer practical,
                cost-effective, and technology-driven solutions to address even
                the most complex logistics and supply chain management
                challenges.
              </p>
            </div>
          </div>
          <p className="my-3">
            No matter the size of your business – whether small, medium, or
            large – we cater to all your technological demands with a modern
            approach that keeps your enterprise running smoothly. Our mission is
            to become your trusted partner in navigating the intricacies of
            modern commerce, ensuring that your business continues to flourish
            and adapt. With {companyname}, you're not just embracing
            logistics solutions – you're embarking on a dynamic journey toward
            growth and success.
          </p>
        </div>
      </section>
      <section className="w3l-features py-5" id="features">
        <div className="container">
          <div className="main-cont-wthree-2">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6 mt-lg-5 mt-4">
                <div className="grids-1 box-wrap">
                  <div className="icon">
                    <i className="fas fa-eye" />
                  </div>
                  <h4>
                    <Link className="title-head mb-3">Our Vision</Link>
                  </h4>
                  <p className="text-para">
                    Our vision is to emerge as a market-leading organization
                    recognized for excellence in maritime services, logistics
                    solutions, and international port operations.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 mt-lg-5 mt-4">
                <div className="grids-1 box-wrap">
                  <div className="icon">
                    <i className="fas fa-bullseye" />
                  </div>
                  <h4>
                    <Link className="title-head mb-3">Our Mission</Link>
                  </h4>
                  <p className="text-para">
                    We provide exceptional maritime services, comprehensive
                    logistics solutions, and state-of-the-art port terminals.
                    Our commitment to continuous improvement and maintaining a
                    secure environment ensures that we are the preferred choice
                    for our clients.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*//grids*/}

      <Footer />
    </>
  );
}
