import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="inner-banner py-5">
        <section className="w3l-breadcrumb text-left py-sm-5 ">
          <div className="container">
            <div className="w3breadcrumb-gids">
              <div className="w3breadcrumb-left text-left">
                <h2 className="inner-w3-title mt-lg-5 mt-4">
                  Door to Door Logistics{" "}
                </h2>
              </div>
              <div className="w3breadcrumb-right">
                <ul className="breadcrumbs-custom-path">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li className="active">
                    <span className="fas fa-arrow-right mx-2" /> Door to Door
                    Logistics{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/*//inner-page*/}
      {/*/w3l-blog*/}
      <section className="w3l-blog blog-single-post">
        <div className="blog py-5">
          <div
            style={{
              margin: "8px auto",
              display: "block",
              textAlign: "center",
            }}
          >
            {/*-728x90-*/}
          </div>
          <div className="container py-lg-5 py-md-4 py-2">
            <div className="row justify-content-center bloghny-page">
              {/*/w3l-blopagew3-left*/}
              <div className="col-lg-8 col-12 blopagew3-left">
                <div className="card mb-4">
                  <div className="card-header p-0 position-relative">
                    <a className="zoom d-block">
                      <img
                        className="card-img-bottom d-block"
                        src="assets/images/Door to Door Logistics.jpg"
                        alt="Card image cap"
                      />
                    </a>
                  </div>
                  <div className="card-body blog-details">
                    <a className="blog-desc">Door to Door Logistics</a>
                    <p>
                      At {companyname}, we take great pride in our
                      meticulous cargo monitoring process, ensuring that your
                      shipment is under our vigilant care from the moment we
                      assume responsibility for it until it reaches your
                      doorstep. Much like nurturing an infant, we safeguard your
                      cargo with the utmost care, providing you with peace of
                      mind throughout its journey.
                    </p>

                    <p>
                      Our commitment to delivering a comprehensive logistics
                      solution is evident through our dedicated single-window
                      customer service division. {companyname} offers a
                      comprehensive Door-To-Door service, catering to various
                      cargo requirements, including Full Container Loads (FCL),
                      Trailer Loads, and Less than Container Loads (LCL). From
                      the point of origin to the final destination, we handle
                      each step of the process with the utmost care to ensure
                      safe delivery.
                    </p>

                    <p>
                      Our Door-To-Door delivery services are designed to
                      simplify logistics management, reduce costs, and minimize
                      the risks associated with product loss or damage. By
                      entrusting us with your cargo, you can concentrate on your
                      core business while we manage the intricacies of
                      logistics.
                    </p>

                    <p>
                      We place a strong emphasis on precision and
                      customer-centric procedures. That's why we develop
                      detailed action plans to streamline the door delivery
                      process. Our collaboration with representatives and agents
                      of the world's leading ocean lines ensures the
                      effectiveness and reliability of our door delivery
                      execution.
                    </p>

                    <p>
                      With {companyname}, you'll enjoy seamless
                      communication from the point of origin to the destination,
                      encompassing all modes of goods movement for the final
                      delivery. Our stage-by-stage flow of information provides
                      you with real-time tracking and status updates, ensuring
                      transparency and keeping you informed at every step of the
                      process.
                    </p>
                  </div>
                </div>
              </div>
              {/*//w3l-blopagew3-left*/}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
